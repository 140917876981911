<template>
  <div :key="counst">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px">
        <!-- 搜索 -->
        <div class="search-box">
          <div class="ayl-item" style="width: 280px">
            <span>运输日期</span>
            <el-date-picker
              class="item"
              v-model="date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :picker-options="pickerOptions"
              @change="queryChange"
            >
            </el-date-picker>
          </div>
          <div class="ayl-item" style="width: 200px">
            <span>订单状态</span>
            <el-select
              class="item"
              v-model="query.orderStatus"
              clearable
              placeholder="请选择"
              @change="queryChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ayl-item" style="width: 200px">
            <span>车辆归属</span>
            <el-select
              class="item"
              v-model="query.carBlong"
              clearable
              placeholder="请选择"
              @change="queryChange"
            >
              <el-option
                v-for="item in $enums.carOwnership.list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ayl-item" style="width: 220px">
            <el-input
              placeholder="客户名称/起运地/目的地/省市区"
              v-model="query.queryContent"
              clearable
              @input="queryChange"
            >
            </el-input>
          </div>
          <el-button type="primary" size="mini" @click="onSearch"
            >查询</el-button
          >
        </div>
        <!-- 表格 -->
        <div class="table-box">
          <el-table
            v-loading="loading"
            :header-cell-style="getRowClass"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              prop="partnerName"
              width="190px"
              fixed="left"
              label="客户名称"
            >
              <template slot-scope="scope">{{scope.row.partnerName || "- -" }}</template>
            </el-table-column>
            </el-table-column>
            <!-- <el-table-column
              prop="partnerAccountName"
               width="190px"
              label="结算客户"
            >
              <template slot-scope="scope">{{scope.row.partnerAccountName || "- -" }}</template>
            </el-table-column> -->
            <el-table-column
              prop="transportTime"
              label="运输日期"
              fixed="left"
              width="100px"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.transportTime">--</span>
                <span v-else>{{
                  scope.row.transportTime | str2ymd
                }}</span>
              </template>
            </el-table-column>
            <!-- 

            <el-table-column
              prop="orderType"
              label="业务类型"
            ></el-table-column> -->
            <el-table-column
              prop="sendAddressDetail"
               width= "180px"
               fixed="left"
              label="起运地"
            >
              <template slot-scope="scope">{{scope.row.sendAddressDetail || "- -" }}</template>
            </el-table-column>

            <el-table-column
              prop="receiveProvinceName"
              width="200px"
              fixed="left"
              show-overflow-tooltip
              label="收货地（省市区）"
            >
              <template slot-scope="scope">{{
                scope.row.receiveProvinceName || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="receiveAddressDetail"
               width= "180px"
               fixed="left"
              label="目的地"
            >
              <template slot-scope="scope">{{scope.row.receiveAddressDetail || "- -" }}</template>
            </el-table-column>
            <el-table-column
              prop="driverName"
              label="驾驶员"
            >
              <template slot-scope="scope">{{scope.row.driverName || "- -" }}</template>
            </el-table-column>
            <el-table-column
              prop="plateNumber"
              label="提货车号"
            >
              <template slot-scope="scope">{{scope.row.plateNumber || "- -" }}</template>
            </el-table-column>

            <el-table-column
              prop="carBelongDes"
              show-overflow-tooltip
              label="车辆归属"
            >
              <template slot-scope="scope">{{
                scope.row.carBelongDes || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="orderStatus"
              label="订单状态"
            >
              <template slot-scope="scope">{{scope.row.orderStatus || "- -" }}</template>
            </el-table-column>
            <el-table-column
              prop="orderId"
               width= "180px"
              label="订单编号"
            ></el-table-column>

            <el-table-column
              label="操作"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="goAllot(scope.row)"
                  >调度</el-button
                >
                <el-button
                  :disabled="!scope.row.isDisabled"
                  type="text"
                  size="small"
                  @click="takeOrder(scope.row)"
                  >交单</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="toVoid(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 20px; text-align: right">
          <el-pagination
            @size-change="mainSizeChange"
            @current-change="mainCurrentChange"
            :current-page.sync="mainCurrentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalNum"
          >
          </el-pagination>
        </div>
      </div>
    </div>


    <el-dialog	 
      title="上传签收单图片"	 
      :visible.sync="centerDialogVisible"	 
      class="dialog"	 
      width="46%"	 
      top="18vh"	 
      center	 
    >	 
     <div style="margin-bottom: 40px;"></div>	 
      <el-form :model="form" ref="form" label-width="140px">	 
        <el-row :gutter="10">	 
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">	 
            <el-form-item label="上传签收单描述：" prop="carTeamRemark">	 
              <el-input v-model="form.carTeamRemark" type="textarea" :rows='4' placeholder="请输入备注(选填)"></el-input>	 
            </el-form-item>	 
            <el-form-item label="上传签收单图片：" prop="receipt" required>          	 
              <ayl-img-upload	 
                v-model="receipt"	 
                :imgWidth="'130px'"	 
                :imgHeight="'130px'"	 
                :imgListLength="9"	 
              ></ayl-img-upload>             	 
            </el-form-item>	 
          </el-col>	 
        </el-row>	 
      </el-form>	 
      <span slot="footer" class="dialog-footer">	 
        <div style="display: flex;justify-content:center;margin-top: 10px">	 
            <el-button type="primary" @click="onSubmit">确定</el-button>	 
            <el-button @click="onClose">取消</el-button>	 
        </div>	 
      </span>	 
    </el-dialog>

  </div>
</template>

<script>
// import copySchedulingVue from "../district-management/scheduling/copy-scheduling.vue";
export default {
  data() {
    return {
      counst: 0,
      nav: [{ name: "外发调度" }],

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: [
        new Date().setTime(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ),
        new Date().getTime(),
      ],
      options: [
        {
          label: "已录单",
          value: "已录单",
        },
        {
          label: "已调度",
          value: "已调度",
        },
        {
          label: "运输中",
          value: "运输中",
        },
      ],

      query: {
        type: 2,
        orderType: null,
        orderStatus: null,
        carBlong: null,
        begin: new Date().setTime(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ),
        end: new Date().getTime(),
        queryContent: null,
        currentPage: 1,
        pageSize: 10,
      },

      tableData: [],
      loading: true,
      mainCurrentPage: 1,
      totalNum: 0,

      orderId: null,
      centerDialogVisible: false, //交单
      submitLoading: false,
      valueImageX: true,
      form: {
        carTeamRemark: "", // 备注
        idCardImgX: {
          //交单图片
          imageKey: "",
          imageUrl: "",
          isClear: true,
        },
      },
      // 签收单
      receipt: [],
    };
  },

  watch: {
    query: {
      deep: true,
      handler: function (v, ov) {
        this.getDate(this.query);
      },
    },
    date: {
      deep: true,
      handler: function (v, ov) {
        this.query.begin = this.date ? this.date[0] : null;
        this.query.end = this.date ? this.date[1] : null;
      },
    },
  },
  methods: {
    onSearch() {
      this.getDate(this.query);
    },
    mainSizeChange(val) {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
      this.query.pageSize = val;
    },
    mainCurrentChange(val) {
      this.query.currentPage = val;
    },
    queryChange() {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
    },
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background:#FAFAFA;color:#262626;font-weight:600;";
      } else {
        return "";
      }
    },
    async getDate(val) {
      this.loading = true;
      let data = await this.$api.OrderList(val);
      this.tableData = data.data;
      this.totalNum = data.totalNum;
      this.loading = false;
    },

    /**
     * 调度
     */
    goAllot(val) {
      if (val.orderStatus === "运输中") {
          this.$notify({	 
            title: "提示",	 
            message: "已调度，运输中...",	 
            type: "warning",	 
          });	
          return
        }
      // this.$router.push({path:'/consign-order/record-order', query: {independent: false}});
      this.$router.push({
        path: "/outgoing-allot/allot",
        query: { orderId: val.orderId },
      });
    },


    async onSubmit() {
      try {	 
        if (this.receipt.length < 1) {	 
          this.$message.warning("请先上传交单图片");	 
          return;	 
        }	 
      } catch (error) {	 
        return;	 
      }
      await this.$api.commitOrder({	 
        orderId: this.orderId,	 
        deliveryRemark: this.form.carTeamRemark,	 
      });	 
      await this.$api.saveImage({	 
        imageVO: {	 
          orderId: this.orderId,	 
          imageUrl: this.receipt,	 
          imageType: "交单",	 
        },	 
      });
      this.$notify({	 
        title: "成功",	 
        message: "交单成功",	 
        type: "success",	 
      });	 
      this.centerDialogVisible = false;	 
      this.getDate(this.query);

    },
    onClose(){
      this.centerDialogVisible = false;
    },
    
    /**
     * 交单弹窗
     */
    takeOrder(val) {
      this.form.carTeamRemark = "";
      this.receipt = [];
      this.centerDialogVisible = true;
      this.orderId = val.orderId;
    },
    toVoid(row) {
      this.$router.push({
        path: "/search-order/order-details",
        query: {
          orderId: row.orderId,
          orderStatus: row.orderStatus,
          isToVoid: true,
        },
      });
    },
  },
  async mounted() {
    this.counst++;
    this.$nextTick(() => {
      this.getDate(this.query);
    });
  },
  async activated() {
    this.counst++;
    this.$nextTick(() => {
      this.getDate(this.query);
    });
  },
};
</script>

<style lang='css' scoped>
.ayl-item {
  display: flex;
  align-content: center;
  margin-right: 20px;
  width: 20%;
  max-width: 300px;
}
.ayl-item > span {
  margin-right: 10px;
  line-height: 28px;
}
.item {
  flex: 1;
}
.el-button--mini {
  height: 28px;
}
</style>